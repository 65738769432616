.download {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--white);
  white-space: nowrap;
  line-height: 16.8px;
  cursor: pointer;
  &.disabled {
    background-color: #E3E3E4;
    color: #000;
    svg{ 
      fill: #000;
    }
  }
  span {
    margin-right: 8px;
    color: var(--white);
  }
  outline: 0;
  border: 0;
  border-radius: 999px;
  background-color: #188038;
}

.popup {
  cursor: pointer;
  animation: fadeIn 0.4s ease;
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: #ef6c6ce5;
  color: var(--white);
  padding: 12px 16px;
  border-radius: 8px;
  z-index: 99;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
