.children_form {
  height: auto;
  margin-top: 40px;
  fieldset {
    margin-bottom: 18px;
  }
}

.errorContainer {
  .errorMessage {
    font-size: 16px;
    color: var(--red);
    margin: 12px 0;
  }
  button {
    margin: 8px 0 16px 0px;
    padding: 0;
    border: none;
    box-shadow: none;
    font-size: 16px;
  }
}

.forgot {
  margin-bottom: 64px;
  .forgotLink {
    font-size: 16px;
    color: var(--blue);
    &:hover {
      text-decoration: underline;
    }
  }
}
