.card {
  width: 40vw;
  margin: 20px;
  p {
    margin: 8px 0;
    color: var(--disable);
  }
}
.input_body {
  position: relative;
}

.input {
  height: 50px;
  font-size: 18px;
  margin: 10px 0;
}

.button {
  font-family: "Lato-700";
  font-style: normal;

  font-size: 16px;
  line-height: 19px;

  color: #2b42a8;
  position: absolute;
  right: 10px;
  top: 19px;
}

.submit {
  font-family: "Lato-700";
  font-style: normal;

  font-size: 16px;
  line-height: 19px;

  color: #2b42a8;
  position: absolute;
  right: 100px;
  top: 19px;
}

.measureSystem {
  width: 100%;
}
