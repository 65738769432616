.subtitle__container {
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  .subtitle__filters {
    display: flex;
    align-items: center;
    button {
      margin-right: 8px;
    }
    .subtitle__export {
      margin-left: 8px;
    }
  }
}

.rangePicker {
  height: 32px;
  border-radius: 50px;
}

.inventetion_control {
  position: relative;
  z-index: 1;
  &__count {
    font-size: 10px;
    padding: 4.5px 6px;
    border: 1px solid var(--blue);
    background-color: var(--white);
    border-radius: 999px;
    z-index: 2;
    color: var(--blue);
    position: absolute;
    top: -20px;
    right: 0px;
    
  }
  &__disabled {
    background-color: #E3E3E4;
    color: #000;
    &:hover {
      background-color: #E3E3E4 !important;
      color: #000 !important;
    }
  }
}

.invintetion_modal__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 300px;
  margin-top: 30px;
}

.invintetion_modal__input_container {
  width: 35%;
  height: 100%;
  margin-right: 30px;
  position: relative;
  p {
    text-align: center;
    color: var(--red);
  }
}

.invintetion_modal__title {
  font-family: "Lato-700";
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 22px;
}

.invintetion_modal__user_list {
  min-height: 300px;
  max-height: 300px;
  width: 65%;

  .invintetion_modal__user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    width: 100%;
    white-space: nowrap;
    button {
      margin: 0 8px;
      border-radius: 50px;
    }
    .invintetion_modal__user_number {
      width: 26px;
      height: 26px;

      background: var(--main-background);

      font-family: "Lato-700";
      font-size: 1.4rem;
      line-height: 1.7rem;
      /* identical to box height */

      color: #525252;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .invintetion_modal__user_name {
      font-family: "Lato-400";
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;

      color: var(--black);
      margin-left: 8px;
      width: 80px;
      max-width: 80px;
      overflow-x: hidden;
      margin-right: 20px;
    }

    .invintetion_modal__user_email {
      font-family: "Lato-400";
      font-size: 1.6rem;
      line-height: 1.9rem;

      color: var(--gray);
      width: 200px;
      max-width: 200px;

      overflow-x: hidden;
    }

    .invintetion_modal__user_count {
      font-family: "Lato-700";
      font-size: 1.6rem;
      line-height: 1.9rem;
      width: auto;
      text-align: right;
    }
  }

  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.invintetion_modal__input_button {
  position: absolute;
  bottom: 20px;
  left: 10%;
  width: 80%;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 80px;
  border-radius: 50px;
  border: 1px solid;
}

.invintetion_modal__user_list_title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4rem;
}

@media (min-width: 1880px) {
  .paddingRadiusFullWidth {
    padding: 16px;
    border-radius: 24px !important;
    width: 100%;
    height: auto;
    line-height: 18px;
    font-size: 16px;
  }
  .invitation {
    width: 100%;
    padding: 0 20px;
  }

  .inventetion {
    &_control,
    &_control__disabled {
      @extend .paddingRadiusFullWidth;
      padding: 10px;
      border-radius: 999px;
    }
  }

  .rangePicker {
    height: 52px;
    padding: 16px;
    border-radius: 24px;
  }

  .subtitle__container {
    margin-top: 28px;
    margin-left: 24px;
    max-width: 328px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
    .subtitle__filters {
      border: 1px solid #e9e9e9;
      padding: 20px;
      border-radius: 28px;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 20px;
      align-items: center;
      button {
        margin: 0;
        margin-bottom: 10px;
        @extend .paddingRadiusFullWidth;
      }
      .subtitle__export {
        width: 100%;
        margin: 0;
        margin-top: 10px;
        button {
          justify-content: center;
          @extend .paddingRadiusFullWidth;
        }
      }
    }
  }
}
