.auth__body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.auth__card_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60vw;
  max-width: 1600px;
  min-width: 800px;
  height: auto;
  img {
    width: 50%;
  }
}

.auth__registration_form {
  width: 50%;
  padding: 40px;
  height: 100%;
  align-self: flex-start;

  .auth__title {
    font-family: "Lato-700";
    font-size: 3.4rem;
    line-height: 4.1rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    img {
      width: 4.7rem;
      padding-right: 1.4rem;
    }
  }
}
