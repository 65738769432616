.dashboard_main__title {
  font-family: "Lato-700";
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 22px;
}

.dashboard_main__user_list {
  margin-top: 20px;
  min-height: 300px;
  max-height: 300px;
  .dashboard_main__user:nth-child(-n + 3) {
    .dashboard_main__user_number {
      background: var(--yellow);
    }
  }

  .dashboard_main__user:hover {
    cursor: pointer;
    background-color: var(--main-background);
  }

  .dashboard_main__user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    width: 100%;
    border-radius: 10px;
    .dashboard_main__user_number {
      width: 26px;
      height: 26px;

      background: var(--main-background);

      font-family: "Lato-700";
      font-size: 1.4rem;
      line-height: 1.7rem;
      /* identical to box height */

      color: #525252;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .dashboard_main__user_name {
      font-family: "Lato-400";
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;

      color: var(--black);
      margin-left: 8px;
      width: 80px;
      max-width: 80px;
      overflow-x: hidden;
      margin-right: 20px;
    }

    .dashboard_main__user_email {
      font-family: "Lato-400";
      font-size: 1.6rem;
      line-height: 1.9rem;

      color: var(--gray);
      width: 200px;
      max-width: 200px;
      min-width: 100px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .dashboard_main__user_count {
      font-family: "Lato-700";
      font-size: 1.6rem;
      line-height: 1.9rem;
      width: auto;
      text-align: right;
      margin-left: 8px;
    }
  }

  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
