.modalContent {
  padding: 16px;
  h1 {
    font-size: 34px;
    margin-bottom: 16px;
    font-weight: 600;
  }
}

.form {
  p {
    margin-top: 8px;
    color: var(--red);
  }
  .input {
    margin-top: 24px;
    input {
      width: 100%;
    }
  }
  button {
    text-align: center;
    margin-top: 96px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 20px;
    height: auto;
  }
}
