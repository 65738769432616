.layout_body {
  background-color: var(--main-background);
  height: 100vh;
}

.layout_content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
  padding-bottom: 30px;
}

.layout_menu {
  padding: 26px;
  background-color: var(--blue);
  border-radius: 28px;
  width: 100px;
}

.layout_sider {
  margin: 30px 0 30px 30px;
  background-color: var(--blue) !important;
  width: 100px !important;
  border-radius: 28px;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 26px;
  }
}

.layout_logo,
.layout_logo img {
  width: 48px !important;
}
.layout__menu_item_icon {
  padding: 0 !important;
}
.layout__menu_item_icon svg {
  width: 30px;
  height: 30px;
  color: var(--white);
}
.layout_logout {
  rotate: 90deg;
  color: var(--white);
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.layout_icon {
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 12px 0;
  height: auto;
}

.layout_icon span {
  margin-inline-start: 0 !important;
  margin-left: 0;
  font-size: 12px;
}

.layout_icon svg {
  margin-bottom: 12px;
  font-size: 22px;
}

