.mainDashboardWithFilters {
  display: block;
  .mainDashboard {
    min-width: 1024px;
  }
  .subtitleDashboard {
    display: none;
  }
}

.subtitleDashboardVertical {
  margin-top: 24px;
}
.secondDashboard {
  min-width: 1024px;
  padding: 0 14px;
}

.dashboard_column {
  span {
    width: 100%;
  }
  width: 100%;
}

.card {
  width: auto;
  padding: 28px;
  height: 450px;
}

.card__title {
  font-family: "Lato-700";
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tooltip {
    button {
      &:active {
        border: none;
        outline: none;
      }
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
      height: 24px !important;
    }
  }
}
.card__subtitle {
  color: #b8bac4;
  font-family: "Lato-400";
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 20px;
}

.userMainChartTabs {
  display: flex;
  align-items: center;
  .item {
    margin: 0 3px;
    button {
      border-radius: 999px;
      &.active {
        background-color: var(--blue);
        color: var(--white);
      }
    }
  }
}
.bodyTab {
  &.ant-select-open {
    span {
      color: var(--white);
    }
  }
  margin: 0 4px;
  width: 92px;
  border: 1px solid #d9d9d9;
  border-radius: 999px !important;
  span {
    width: auto;
    
  }
  &.active {
    background-color: var(--blue);
    span {
      color: var(--white) !important;
    }
  }
}

.companyMainChart {
  height: 100%;
  h3 {
    font-size: 20px;
  }
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart {
  margin-top: 32px;
}

@media (min-width: 1880px) {
  .subtitleDashboardVertical {
    display: none;
  }

  .mainDashboardWithFilters {
    display: flex;
    align-items: flex-start;
    .mainDashboard {
      width: 1400px;
    }
    .subtitleDashboard {
      margin-top: 0;
      display: block;
    }
  }
  .secondDashboard {
    max-width: 1400px;
    padding: 0 14px;
  }
}
