html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  height: 100%;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  font-size: 10px;
  font-family: "Lato-400";
  overflow-y: hidden;
}

body {
  --white: #ffffff;
  --black: #101012;
  --gray: #b8bac3;
  --main-background: #f6f7f8;
  --blue: #2b42a8;
  --light-blue: #edf0fb;
  --green: #45af3f;
  --second-gray: #9299a2;
  --red: #e03e3b;
  --yellow: #f0c74b;
  --brown: #393014;
  --second-background: #e9ebfa;
  --pirple: #7982c0;
  --disable: #818b8b;
  min-width: 1300px;
  background-color: var(--main-background);

  --accepted: #5ac654;
  --rejected: #cf4d4b;
  --pending: #d2d255;

  width: 100%;

  overflow-y: unset !important;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

@font-face {
  font-family: "Lato-100";
  src: url("./assets//fonts/Lato-Thin.ttf");
}

@font-face {
  font-family: "Lato-300";
  src: url("./assets//fonts/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato-400";
  src: url("./assets//fonts/Lato-Regular.ttf");
}
@font-face {
  font-family: "Lato-700";
  src: url("./assets//fonts/Lato-Bold.ttf");
}
@font-face {
  font-family: "Lato-900";
  src: url("./assets//fonts/Lato-Black.ttf");
}

::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--gray);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--gray);
}
