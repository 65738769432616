.userCategoriesTabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  .item {
    margin: 5px 2px;
    button {
      border-radius: 999px;
      font-size: 12px;
      width: 100%;
      &.active {
        background-color: var(--blue);
        color: var(--white);
      }
    }
  }
}

.header {
  width: 100%;
}

.title {
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tooltip {
    button {
      &:active {
        border: none;
        outline: none;
      }
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
      height: 24px !important;
    }
  }
}

.cardContent {
  padding: 22px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.chart {
  width: 100%;
  min-height: 200px;
}

@media (min-width: 1880px) {
  .userCategoriesTabs {
    .item {
      flex: 1;
    }
  }
}
