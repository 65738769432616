.base_button {
  height: 66px;
  padding: 0px 21px;

  width: calc(100% - 42px);

  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-family: "Lato-700";
}
