.input {
  margin-top: 48px;
  width: 100%;
}

.controls {
  margin-top: 64px;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.info {
  margin-top: 48px;
  h4 {
    font-size: 24px;
    line-height: 40px;
  }
}
