.input {
  margin-top: 24px;
  width: 100%;
  fieldset {
    margin-top: 24px;
  }
}

.controls {
  margin-top: 64px;
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
