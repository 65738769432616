.plusMinus {
  width: 35px;
  height: 35px;
  border: 0;
  font-size: 1.5em;
  position: relative;
}

.plusMinus span {
  position: absolute;
  transition: 300ms;
  background-color: var(--blue);
  border-radius: 2px;
}

.plusMinus span:first-child {
  top: 25%;
  bottom: 25%;
  width: 10%;
  left: 45%;
}

.plusMinus span:last-child {
  left: 25%;
  right: 25%;
  height: 10%;
  top: 45%;
}

.active span {
  transform: rotate(90deg);
}

.active span:last-child {
  left: 50%;
  right: 50%;
}
