.payboard_container {
  display: flex;
  border-radius: 28px;
  padding: 0 28px;
}

.formContainer {
  padding: 0 28px;
  max-width: 1046px;

  h3 {
    font-size: 32px;
    margin-bottom: 12px;
    line-height: 40px;
  }

  a {
    font-size: 20px;
  }
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}