.pay_condition__container {
  width: auto;
  background-color: var(--white);
  padding: 28px;
  color: var(--black);
  margin: 1px;
  
}
.pay_condition__container:last-child,
.pay_condition__container_active:last-child {
  border-radius: 0 28px 28px 0;
}

.pay_condition__container:first-child,
.pay_condition__container_active:first-child {
  border-radius: 28px 0 0 28px;
}

.pay_condition__container_active {
  width: auto;
  background-color: var(--blue);
  padding: 28px;
  box-shadow: 0px 16px 24px rgba(119, 119, 119, 0.44);
  color: var(--white);
  
}

.pay_condition__title {
  font-family: "Lato-700";
  font-size: 2rem;
  line-height: 2.4rem;
  margin-bottom: 11px;
}

.pay_condition_section__icon_active {
  color: var(--green);
  background-color: var(--white);
  border-radius: 50%;
  margin-right: 6px;
}
.pay_condition_section__icon_unable_active {
  color: var(--pirple);
  margin-right: 6px;
}
.pay_condition_section__icon_unable {
  color: var(--disable);
  margin-right: 6px;
}

.pay_condition_section__footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.pay_condtion_price__container {
  cursor: pointer;
  width: auto;
  height: 70px;
  border: 1px solid var(--blue);
  width: calc(50% - 6px);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  padding: 13px 18px;

  background-color: var(--white);
  color: var(--black);
}
.pay_condition_price__title {
  font-family: "Lato-700";
  font-size: 1.8rem;
  line-height: 2.2rem;
}
.pay_condition_price__subtitle {
  font-family: "Lato-400";
  line-height: 1.4rem;
  line-height: 1.7rem;
  color: var(--disable);
}

.pay_condition_price__subtitle_active {
  font-family: "Lato-400";
  line-height: 1.4rem;
  line-height: 1.7rem;
  color: var(--pirple);
}
.pay_condtion_price__container_active {
  cursor: pointer;
  width: auto;
  height: 70px;
  border: 1px solid var(--white);
  width: calc(50% - 6px);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  padding: 13px 18px;

  background-color: var(--blue);
  color: var(--white);
}
