.paystatus_body {
  background: var(--yellow);
  border-radius: 9px;
  padding: 5.5px 8px;
  display: flex;
  align-items: center;

  &__disabled {
    background-color: #E3E3E4;
    color: #000;
  }
}

.paystatus_body svg {
  font-size: 1.9rem;
  margin-left: 4px;
  margin-right: 10px;
}

.paystatus_body button {
  font-family: "Lato-400";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.4rem;
  /* identical to box height */

  color: var(--black);
}

.paystatus_body button:hover {
  border-color: var(--gray) !important;
  color: var(--gray) !important;
}

.paystatus_title {
  font-family: "Lato-700";
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin-right: 10px;

  color: var(--brown);
}

.paystatus_expiers {
  margin-right: 10px;
}