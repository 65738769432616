.contact {
  margin-top: 64px;
  h3 {
    font-weight: 600;
  }
  .form {
    margin-top: 32px;
    padding: 40px;
    border-radius: 28px;
    background-color: var(--blue);
    h4 {
      text-align: center;
      color: var(--white);
      font-size: 34px;
      margin-bottom: 24px;
    }
    .inputs {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 16px;
      column-gap: 16px;

      fieldset {
        input {
          width: 100%;
        }
      }
    }
    .textarea {
      width: 100%;
      margin-top: 16px;
      textarea {
        border-radius: 12px;
        height: 132px;
        width: 100%;
        font-size: 20px !important;
        padding: 20px;
      }
    }
    .loaded {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      h4 {
        color: var(--white);
        font-size: 24px;
        margin: 0;
        margin-right: 16px;
        line-height: 36px;
      }
    }
    .submit {
      margin-top: 24px;
      width: 100%;
      text-align: center;
      button {
        padding: 20px 30px;
        height: auto;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
      }
    }
  }
}
