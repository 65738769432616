.column__container {
  width: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column__body {
  width: 48px;
  height: 200px;
  background-color: var(--second-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  overflow: hidden;
}

.column__progress {
  -webkit-transition: all 2s ease-out;
  -moz-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: height 0.4s ease-out;
  background-color: var(--blue);
  width: 100%;
  border-radius: 12px;
  animation: slideUp 0.4s ease;
}

.column__subTitle {
  margin-top: 12px;
  color: var(--gray);

  font-family: "Lato-400";
  font-size: 14px;
  line-height: 1.7rem;

  white-space: nowrap;
}

.column__title {
  color: var(--pirple);
  font-family: "Lato-700";
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-top: 8px;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
