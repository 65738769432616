.helpContainer {
  padding: 0 28px;
  max-width: 1046px;
  h3 {
    font-size: 34px;
    margin-bottom: 12px;
  }
  a {
    font-size: 20px;
  }
}

.collapse {
  background-color: transparent;
  div[role="button"] {
    align-items: center !important;
  }
  .ant-collapse-header {
    .ant-collapse-expand-icon {
      height: auto;
    }
  }
}

.collapseBody {
  margin-top: -8px;
  font-size: 20px;
  color: var(--disable);
  font-weight: 400;
  line-height: 25.4px;
}

.collapseHeader {
  color: var(--blue);
}
