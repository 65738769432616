.header_title {
  font-family: "Lato-700";
  font-style: normal;
  font-size: 3.4rem;
  line-height: 4.1rem;
  display: flex;
  align-items: center;
}

.header_body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  padding-bottom: 0;
  margin-bottom: 20px;
}

.header_children {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header_children__title {
    margin-left: 20px;
    text-align: end;
    h3 {
      font-family: "Lato-700";
      font-size: 2.4rem;
      line-height: 2.9rem;
    }
    p {
      margin-top: 4px;
      font-family: "Lato-700";
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: var(--disable);
    }
  }
}
