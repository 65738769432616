.field_body {
  position: relative;
}

.base_input {
  height: 66px;
  padding: 0px 21px;

  width: calc(100% - 42px);

  background: var(--white);
  border: 1px solid var(--main-background);
  color: var(--black);
  border-radius: 12px;

  font-size: 2rem;
  font-family: "Lato-400";
}

.base_input::placeholder {
  color: var(--gray);
}

.base_input:active,
.base_input:focus,
.base_input:focus-visible,
.base_input:focus-within {
  outline-color: var(--blue);
}



.base_label {
  position: absolute;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: var(--gray);
  left: 2.1rem;
  top: 0.8rem;
  z-index: 10;
}
